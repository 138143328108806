<template>
    <header class="page-title">
        <h1><i class="bi bi-gear-fill"></i> マスタ管理 - 確実度</h1>
    </header>

    <!-- 検索欄 -->
    <section class="section">
        <div class="row align-items-end">
            <div class="col-6">
                <label class="form-label">事業部</label>
                <form-select
                    v-model="selected_department_id"
                    :options="options_department"
                    :disabled="loading_count > 0"
                ></form-select>
            </div>
            <div class="col-6">
                <button @click="search()" :disabled="loading_count > 0" class="btn btn-primary">表示</button>
            </div>
        </div>
    </section>

    <section class="section">
        <inline-loader v-if="loading_count > 0"></inline-loader>
        <div v-else class="col-sm-36 col-md-26 col-lg-20">
            <table class="table">
                <thead>
                    <tr class="table-dark">
                        <th class="text-center col-1"></th>
                        <th class="text-center col-8">確実度</th>
                        <th class="text-center col-4">着地予測％</th>
                        <th class="text-center col-4"></th>
                    </tr>
                </thead>
                <template v-if="certainties.length">
                    <draggable
                            v-model="certainties"
                            item-key="certainty_id"
                            tag="tbody"
                            handle=".handle"
                            @end="sort()"
                    >
                        <template #item="{element}">
                            <tr>
                                <td class="text-center handle"><i class="bi bi-chevron-bar-expand"></i></td>
                                <td class="align-middle">
                                    <form-input v-model="element.certainty_name"/>
                                </td>
                                <td class="align-middle">
                                    <form-input-number v-model="element.certainty_rate" min="0" max="999" />
                                </td>
                                <td class="text-center">
                                    <button type="button" class="btn btn-info me-2" @click="update(element)">保存</button>
                                    <button type="button" class="btn btn-outline-danger ms-2" @click="removeReady(element)">削除</button>
                                </td>
                            </tr>
                        </template>
                    </draggable>
                </template>
                <tfoot>
                    <tr>
                        <td></td>
                        <td class="align-middle"><form-input v-model="certainty_to_create.certainty_name" /></td>
                        <td class="align-middle"><form-input-number v-model="certainty_to_create.certainty_rate" /></td>
                        <td class="text-center">
                            <button class="btn btn-info" @click="create">追加</button>
                        </td>
                    </tr>
                </tfoot>
            </table>

            <div class="mt-5 alert alert-warning"><i class="bi bi-info-circle"></i> 「着地予測％」は着地予測レポートでの着地予測件数の計算に使用されます。</div>
        </div>
    </section>

    <confirm-dialog ref="confirmRemove" @ok="remove">
        <p>削除してよろしいですか？</p>
    </confirm-dialog>

</template>

<script>
import draggable from 'vuedraggable';
import Certainty from '@/models/entities/certainty';
import Department from '@/models/entities/department';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import FormInput from '@/components/forms/FormInput';
import FormInputNumber from '@/components/forms/FormInputNumber';
import FormSelect from '@/components/forms/FormSelect';
import ConfirmDialog from '@/components/tools/ConfirmDialog.vue';

export default {
    name: 'MasterCertainty',
    components: {
        draggable,
        InlineLoader,
        FormInput,
        FormInputNumber,
        FormSelect,
        ConfirmDialog,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            // 検索用
            options_department: [],
            selected_department_id: null,
            // ローディング
            loading_count: 0,
            // 表示データ
            certainties: [],
            // 新規
            certainty_to_create: new Certainty(),
            // 削除
            certainty_to_remove: null,
        }
    },
    mounted() {
        this.fetchDepartments();
    },
    methods: {
        // 事業部一覧取得
        fetchDepartments() {
            // ローディング開始
            this.loading_count++;
            // option空に
            this.options_department.splice(0);

            this.$http.get('/master/department')
            .then(response => {
                for (let row of response.data) {
                    let department = new Department(row);
                    this.options_department.push({value: department.department_id, label: department.department_name});
                }

                //事業部未登録の場合
                if (this.options_department.length === 0) {
                    this.showErrorMessage('事業部が登録されていません。先に事業部を登録してください');
                    this.$router.push({name: 'MasterDepartment'});
                }

                // 初期値事業部セット
                this.selected_department_id = this.options_department[0].value;

                // 確実度一覧取得
                this.search();
            })
            .finally(() => {
                this.loading_count--;
            })
        },
        // 検索
        search() {
            // 事業部セットされているか確認
            if (this.$helper.isBlank(this.selected_department_id)) {
                this.showErrorMessage('事業部を選択してください');
                return;
            }

            this.loading_count++;

            //表示データ初期化
            this.certainties.splice(0);

            this.$http.get('/master/certainty', {
                params: {
                    department: this.selected_department_id
                }
            })
            .then(response => {
                for (let row of response.data) {
                    this.certainties.push(new Certainty(row));
                }
            })
            .finally(() => {
                //ローディング終了
                this.loading_count--;
            })
        },
        // 新規登録
        create() {
            // バリデーション
            if (this.$helper.isBlank(this.certainty_to_create.certainty_name)) {
                this.showErrorMessage('確実度名を入力してください');
                return;
            }
            if (this.$helper.isBlank(this.certainty_to_create.certainty_rate)) {
                this.showErrorMessage('着地予測を入力してください');
                return;
            }

            this.startScreenLoading();

            // 事業部セット
            this.certainty_to_create.department_id = this.selected_department_id;

            this.$http.post('/master/certainty', this.certainty_to_create)
            .then(response => {
                this.certainties.push(new Certainty(response.data));
                this.certainty_to_create = new Certainty();
                this.showMessage('追加しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
        // 保存（編集）
        update(certainty) {
            // バリデーション
            if (this.$helper.isBlank(certainty.certainty_name)) {
                this.showErrorMessage('確実度名を入力してください');
                return;
            }
            if (this.$helper.isBlank(certainty.certainty_rate)) {
                this.showErrorMessage('着地予測を入力してください');
                return;
            }

            this.startScreenLoading();

            this.$http.put(`/master/certainty/${certainty.certainty_id}`, certainty)
            .then(() => {
                this.showMessage('保存しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
        // 削除モーダル
        removeReady(certainty) {
            this.certainty_to_remove = certainty;
            this.$refs.confirmRemove.show();
        },
        // 削除処理
        remove() {
            this.startScreenLoading();

            this.$http.delete(`/master/certainty/${this.certainty_to_remove.certainty_id}`)
            .then(() => {
                const remove_index = this.certainties.findIndex((row) => row.certainty_id === this.certainty_to_remove.certainty_id);
                this.certainties.splice(remove_index, 1);
                this.certainty_to_remove = null;
                this.showMessage('削除しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
        // 並び順更新
        sort() {
            this.startScreenLoading();

            const certainties = this.certainties.map(certainty_object => certainty_object.certainty_id);

            this.$http.post('/master/certainty/sort', {
                certainties: certainties,
            })
            .finally(() => {
                this.endScreenLoading();
            })
        }
    }
}
</script>

<style scoped>

</style>
